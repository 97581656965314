import React, {useState, useCallback, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import { COLOR_THEME } from '../../post-security/common/constants'

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ArticleIcon from '@mui/icons-material/Article';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	type_option: {
		display: 'flex',
        '&:hover': {
            border: '1px solid #093D61',
        }
	},
    dropzone: {
        textAlign: "center",
        padding: "10px",
        border: "1px #bdbdbd solid",
        backgroundColor: "#fafafa",
        color: "#717171",
        [theme.breakpoints.up('sm')]: {
            padding: "20px",
        },
    }
}));
 
//Function that pulls the data and sends it to the Table Renderer
const NewRequirement = props => {
    let { showDialog, setShowDialog, requirement_id, formSubmitContainer,setRequirement_id } = props;
    const classes = useStyles();
    const [myFiles, setMyFiles] = useState([])

    useEffect(() => {
        setMyFiles([])
    },[showDialog])

    const onDrop = useCallback(acceptedFiles => {
        let total_file_length = acceptedFiles.length + myFiles.length
        if (total_file_length === 1) {
            setMyFiles([...acceptedFiles])
        } else {
            const allArePdf = acceptedFiles.every((file) => file.type === 'application/pdf') && myFiles.every((file) => file.type === 'application/pdf');
            if (allArePdf) {
                setMyFiles([...myFiles, ...acceptedFiles])
            } else {
                setMyFiles([...acceptedFiles])
            }
        }
    }, [myFiles])

    const { getRootProps, getInputProps } = useDropzone({
    onDrop, 
    multiple: true
    })

    const formSubmit = () => {
        var SubmissionFormData = new FormData();
        let file_counter = 1;
        myFiles.map(file => {
            SubmissionFormData.append('attachment'+file_counter, file);
            file_counter += 1;
        })
        formSubmitContainer(SubmissionFormData, requirement_id);
        setShowDialog(false);
        setRequirement_id(null);
    }
    
    return (
        <div>
            <Dialog
                open={showDialog}
                onClose={() => {
                    setMyFiles([])
                    setShowDialog(false)
                    setRequirement_id(null);
                }}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Upload Documents</DialogTitle>
                <DialogContent>
                    <br />
                    <div {...getRootProps({className: classes.dropzone})}>
                        <input {...getInputProps()} />
                        <Typography variant='subtitle1'>Drag and drop or click to select upto 5 files. Multiple files are only allowed if all files are pdf.</Typography>
                    </div>
                    <br />
                    {myFiles.length > 0 && (
                        <div style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                            <Typography variant='subtitle1'>Selected Files:</Typography>&nbsp;
                            {myFiles.map(file => (
                                <>
                            <Chip 
                                icon={<ArticleIcon />} 
                                label={file.path.substring(0,25)} 
                                color='primary'
                                size='small'
                            />&nbsp;&nbsp;
                                </>
                            ))}
                        </div>
                    )}
                    <br />
                    <Button 
                        variant="contained"
                        onClick={() => formSubmit()}
                        disabled = {myFiles.length > 0 ? false : true}
                        style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}}
                    >
                        Submit
                    </Button>
                </DialogContent>
                <DialogActions style={{padding: '15px', paddingRight: '25px', paddingBottom: '25px'}}>
                    <Button variant='contained' onClick={() => setShowDialog(false)}>Back to all requirements</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


export default NewRequirement;